interface IMetadataFieldProps {
  label: string;
  meta: JSX.Element | string;
}

const MetadataField = ({ label, meta }: IMetadataFieldProps) => {
  return (
    <div className="flex items-center gap-3 text-sm">
      <label className="text-aurometalsaurus dark:text-gray-400 font-normal min-w-[180px]">
        {label}
      </label>
      {meta}
    </div>
  );
};

export default MetadataField;
