import get from "lodash/get";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import Icon from "shared/components/icon/Icon";
import CommonDropdownV2 from "shared/componentsV2/common-dropdownV2";
import { useCompany } from "shared/context/CompanyProvider";
import clsx from "clsx";
import getTags from "shared/componentsV2/tags-fieldV2/api/getTags";
import { useQuery } from "@tanstack/react-query";
import { useArticleDetails } from "modules/horizonScanning/article-details/ArticleDetails";

type TArticleTagsDropdownProps = {
  readonly disabled?: boolean;
};

const ArticleTagsDropdown = ({ disabled }: TArticleTagsDropdownProps) => {
  const { currentCompany } = useCompany();
  const { article, updateArticle } = useArticleDetails();

  const { data: tagsOptions } = useQuery({
    queryKey: ["getTags", { company: currentCompany.id }],
    queryFn: async () => {
      if (currentCompany.id) {
        const response = await getTags({ company: currentCompany.id });

        return (response?.data.tags || []).map((tag) => ({ label: tag, value: tag, data: tag }));
      }
    },
    enabled: currentCompany.id !== undefined,
  });

  const selectedTags =
    article.tags?.map((tag) => ({
      value: tag,
      label: tag,
      data: tag,
    })) ?? [];

  return (
    <>
      <div className="flex gap-4 flex-wrap">
        {selectedTags
          .map((tag) => {
            return (
              <div
                key={tag.value}
                className="flex justify-between items-center gap-1 px-1.5 py-0.5 rounded-md bg-[#E1EFFE]"
              >
                <div className={clsx("text-sm font-medium text-crayolasblue")}>{tag.label}</div>
                {!disabled ? (
                  <button
                    disabled={!article.is_active}
                    onClick={() => {
                      const filteredTags = selectedTags.filter((item) => item.value !== tag.value);

                      updateArticle?.({ tags: filteredTags.map((tag) => String(tag.value)) });
                    }}
                  >
                    <Icon type="close-small" fill={true} size="icon-xxs" badgeColor="#1C64F2" />
                  </button>
                ) : null}
              </div>
            );
          })
          .concat([
            <DropdownMenu.Root>
              <DropdownMenu.Trigger asChild disabled={disabled || !article.is_active}>
                <button
                  aria-label="Customise options"
                  title="Change View"
                  disabled={disabled}
                  className="focus-visible:outline-none"
                >
                  {selectedTags?.length > 0 ? (
                    <span className="block add_btn p-0.5 border border-brightgray rounded shadow-sm">
                      {!disabled ? <Icon type="plus-btn" fill={true} size="icon-xs" /> : null}
                    </span>
                  ) : (
                    <div className="flex items-center gap-1">
                      <span className="text-santagrey dark:text-gray_400 text-sm">Select tags</span>
                      <Icon type="chevron-down" size="icon-sm" fill={true} />
                    </div>
                  )}
                </button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content
                className="z-10 relative min-w-[15rem]"
                align="start"
                sideOffset={5}
                side="bottom"
              >
                <CommonDropdownV2<string>
                  isMultiSelect={true}
                  className="common_dropdown_v2"
                  classNamePrefix="combobox_dropdown"
                  placeholder="Search Tags..."
                  isDisabled={disabled}
                  constantOptions={tagsOptions}
                  selectedValue={[]}
                  onChange={(data) => {
                    if (!article.is_active) return;
                    const tags = selectedTags.find((tag) => tag.value === data[0].value)
                      ? selectedTags
                      : [...selectedTags, ...data];

                    updateArticle?.({ tags: tags.map((tag) => String(tag.value)) });
                  }}
                  menuIsOpen={true}
                  searchKey="search"
                  OptionComponent={(data) => {
                    return (
                      <div className="flex gap-2 items-center w-full justify-between">
                        <div className="flex gap-2 items-center">
                          <span className="text-brightgrey text-sm dark:text-uniquegrey whitespace-nowrap">
                            {get(data, "data.label", "")}
                          </span>
                        </div>
                        {selectedTags.find((tag) => tag.value === get(data, "data.value", "")) ? (
                          <Icon
                            type="checkmark"
                            fill={true}
                            size="icon-xs"
                            className="checkmark_icon"
                          />
                        ) : null}
                      </div>
                    );
                  }}
                />
              </DropdownMenu.Content>
            </DropdownMenu.Root>,
          ])}
      </div>
    </>
  );
};

export default ArticleTagsDropdown;
