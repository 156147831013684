import React, { FC } from "react";

import clsx from "clsx";

import Icon from "shared/components/icon/Icon";

import styles from "assets/css/formControls.module.css";

import { BadgeProps } from "../types";

const Badge: FC<BadgeProps> = ({
  handleClear,
  badgeSize = "md",
  badgeType = "primary",
  icon,
  badgeTitle,
  badgeColor,
  badgeBgColor,
  iconSize = "md",
  badgeID,
  ...props
}) => {
  return (
    <div
      {...props}
      className={clsx(
        styles.badge,
        styles[`badge_${badgeSize}`],
        styles[`badge_${badgeType}`],
        props.className
      )}
      style={{ backgroundColor: badgeBgColor }}
    >
      {icon ? icon : null}
      {badgeTitle ? (
        <label
          htmlFor={badgeID || ""}
          style={{ color: badgeColor }}
          id="badgeTitle"
          className="select-none"
        >
          {badgeTitle}
        </label>
      ) : null}
      {handleClear ? (
        <button onClick={handleClear}>
          <Icon
            type="x-marks"
            fill={true}
            size={`icon-${iconSize}`}
            className="clearIcon"
            badgeColor={badgeColor}
          />
        </button>
      ) : null}
    </div>
  );
};

export default Badge;
