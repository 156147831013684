import React from "react";

import { useWindowSize } from "usehooks-ts";

import Can from "shared/components/can/Can";
import { useSidebar } from "shared/store/sidebar";
import {
  REPORT_MODULE,
  VENDOR_MODULE,
  QUESTIONNAIRE_MODULE,
  RISK_MODULE,
} from "shared/helpers/ability";

import styles from "assets/css/sidebar.module.css";

import RenderList from "./RenderList";
import { SideBarItems } from "./types";

export default function SidebarModule({
  sideBarItems,
  onClick,
  isUpgradeVisible = false,
  selectedModule,
}: {
  sideBarItems: Array<SideBarItems>;
  onClick: (index: number) => void;
  isUpgradeVisible?: boolean;
  selectedModule?: number | null;
}) {
  const { height } = useWindowSize();

  const setIsVisible = useSidebar((state) => state.setIsVisible);

  return (
    <aside
      className={styles.sidebarContent}
      data-testid="sidebar_module"
      aria-label="Sidebar"
      style={{ height: height - (isUpgradeVisible ? 214 : 145) }}
    >
      <div className="h-full">
        <ul className="md:space-y-2">
          {sideBarItems.map((ele, index) =>
            ele.action && ele.subject ? (
              [VENDOR_MODULE, REPORT_MODULE, QUESTIONNAIRE_MODULE, RISK_MODULE].includes(
                ele.subject
              ) ? (
                <RenderList
                  key={index}
                  ele={ele}
                  index={index}
                  onClick={(index) => {
                    if (ele?.items?.length === 0 || !ele?.items) setIsVisible(false);
                    onClick(index);
                  }}
                  isSelected={selectedModule === index}
                />
              ) : (
                <Can key={index} I={ele.action} a={ele.subject}>
                  <RenderList
                    ele={ele}
                    index={index}
                    onClick={(index) => {
                      if (ele?.items?.length === 0 || !ele?.items) setIsVisible(false);
                      onClick(index);
                    }}
                    isSelected={selectedModule === index}
                  />
                </Can>
              )
            ) : ele.visibleToProd === true ? (
              <RenderList
                key={index}
                ele={ele}
                index={index}
                onClick={(index) => {
                  setIsVisible(false);
                  onClick(index);
                }}
                isSelected={selectedModule === index}
              />
            ) : null
          )}
        </ul>
      </div>
    </aside>
  );
}
