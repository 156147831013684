import { useState } from "react";

import { useParams, useNavigate, Link } from "react-router-dom";

import { ArrowRightIcon, ArrowTopRightOnSquareIcon, ShareIcon } from "@heroicons/react/24/solid";
import { Button } from "flowbite-react";
import { useDarkMode } from "usehooks-ts";

import { cn } from "shared/componentsV2/utils";
import { MOBILE_DEVICE } from "shared/helpers/constant";
import CentralSpinner from "shared/components/spinner/CentralSpinner";
import Icon from "shared/components/icon/Icon";
import NoDataAvailable from "shared/components/no-data-available";
import useScreen from "shared/hooks/useScreen";

import logoTextOnly from "assets/images/logo-text-only.svg";
import logoTextOnlyWhite from "assets/images/logo-text-only-white.svg";
import style from "assets/css/articlesV2.module.css";

import { FeedArticle } from "../types";
import ArticleDetails from "./ArticleDetails";
import usePublicArticleQuery from "./hooks/usePublicArticleQuery";

const ArticleDetailsPublicPage = () => {
  const { isDarkMode } = useDarkMode();
  const { articleId } = useParams();
  const navigate = useNavigate();
  const deviceType = useScreen();
  const [shareModalArticle, setShareModalArticle] = useState<FeedArticle | null>(null);

  const { data, isLoading: isLoadingArticle } = usePublicArticleQuery({
    articleId: articleId ? Number(articleId) : undefined,
    enabled: !!articleId,
  });

  const isLoading = isLoadingArticle;

  const article = data?.data
    ? {
        ...data.data,
        id: String(data.data.object_id),
      }
    : undefined;

  if (deviceType === MOBILE_DEVICE) {
    document.querySelector("body")?.classList.add("overflow-y-auto");
  }

  return (
    <>
      {!isLoading && article ? (
        <div>
          <div className="flex flex-wrap gap-4 justify-between items-center mx-8 mt-[1rem]">
            <div className=" cursor-pointer">
              <img
                src={isDarkMode ? logoTextOnlyWhite : logoTextOnly}
                alt="logo"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="flex flex-wrap gap-4">
              <Button
                className="flex-grow"
                pill
                size="xs"
                color="dark"
                onClick={() => navigate("/signup")}
              >
                Sign up for free
              </Button>
              <Button
                className="flex-grow"
                pill
                size="xs"
                color="light"
                onClick={() => navigate("/signup")}
              >
                <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-2" />
                Article from Grand's Horizon Scanning
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-center mt-8 md:mt-[3.75rem]">
            <ArticleDetails
              article={article}
              hideForm
              hideToDos
              hideClassificationBadge
              hideNonShareActions
              headless
              hideSimilarArticles
              shareModalArticle={shareModalArticle}
              setShareModalArticle={setShareModalArticle}
            />
            <div className={cn(style.article_details_grid, "!pt-0")}>
              <div className="flex gap-4">
                <Button pill size="xs" color="light" onClick={() => setShareModalArticle(article)}>
                  <ShareIcon className="w-4 h-4 mr-2" />
                  Share
                </Button>
                <Button pill size="xs" color="light" href={article.link} target="_blank">
                  Read original post
                </Button>
              </div>
              <div className="w-full bg-mirage text-white dark:border dark:border-jet dark:bg-darkjunglegreenss dark:text-lighthouse py-10 px-8 rounded-2xl">
                <Icon type={isDarkMode ? "logo" : "logoWhite"} className="!w-8 !h-8" />
                <h1 className="text-2xl leading-8 mt-6 mb-2.5 font-[GrandSansDisplay]">
                  Let’s make compliance fun again
                </h1>
                <p className="mb-6 text-sm max-w-[70ch]">
                  We are reinventing GRC for the finance community. Combining AI-enabled solutions
                  with human expertise in our next gen compliance platform.
                </p>
                <Button size="xs" color="light" onClick={() => navigate("/signup")}>
                  Sign up for free
                  <ArrowRightIcon className="w-4 h-4 ml-2" />
                </Button>
              </div>
              <div className="flex items-center gap-2 mb-12">
                <span className="text-sm dark:text-white">Visit us in</span>
                <div className="flex gap-2">
                  <Link
                    to="https://www.linkedin.com/company/grandcompliance/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="linkedin" className="w-5 h-5 stroke-none" />
                  </Link>
                  <Link
                    to="https://www.facebook.com/p/Grand-Compliance-100089344027128/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="facebook" className="w-5 h-5 stroke-none" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {!article && !isLoading ? (
        <div className="h-screen">
          <NoDataAvailable />
        </div>
      ) : null}
      {isLoading ? <CentralSpinner /> : null}
    </>
  );
};

export default ArticleDetailsPublicPage;
