import React, { useContext, useState } from "react";

import { AxiosResponse } from "axios";
import truncate from "lodash/truncate";

import { cn } from "shared/componentsV2/utils";
import { useCompany } from "shared/context/CompanyProvider";
import { useHorizonScanningMenuBar } from "shared/storeV2/horizonScanningMenubar";
import { useHorizonScanningViewMode } from "shared/storeV2/horizonScanningViewMode";
import Header from "shared/components/main-content/Header";
import Spinner from "shared/components/spinner/Spinner";
import style from "assets/css/articlesV2.module.css";

import { FeedArticle, FeedGroupItem, UpdateArticleParams, UpdateArticleResponse } from "../types";
import ActionBar from "./components/action-bar";
import ArticleClassifications from "./components/article-classifications";
import ArticleDetailsMetadata from "./components/article-details-metadata";
import ArticleFormMetadata from "./components/article-form-metadata";
import ArticleHeader from "./components/article-header";
import ArticleSimilarArticles from "./components/article-similar-articles";
import ArticleSummary from "./components/article-summary";
import ArticleToDosModal from "./components/article-to-dos-modal";
import ShareArticleModal from "../shared/components/share-article-modal";

interface IArticleDetailsContext {
  article: FeedArticle;
  similarArticles?: FeedArticle[];
  feed?: FeedGroupItem & {
    url: string;
  };
  hideForm?: boolean;
  hideToDos?: boolean;
  hideClassificationBadge?: boolean;
  headless?: boolean;
  hideNonShareActions?: boolean;
  showToDosModal?: boolean;
  hideNavigation?: boolean;
  loadingNavigation?: boolean;
  loadingSimilarArticles?: boolean;
  disableNextArticle?: boolean;
  disablePreviousArticle?: boolean;
  setShowToDosModal?: (showToDosModal: boolean) => void;
  onPreviousArticle?: () => void;
  onNextArticle?: () => void;
  onMarkAsRead?: () => void;
  onArchive?: () => void;
  onBookmark?: () => void;
  updateArticle?: (
    params: Partial<UpdateArticleParams>
  ) => Promise<AxiosResponse<UpdateArticleResponse>>;
  openShareModal?: (article: FeedArticle) => void;
}

const ArticleDetailsContext = React.createContext<IArticleDetailsContext>({
  article: {} as FeedArticle,
});

interface IArticleDetailsProps {
  article: FeedArticle;
  similarArticles?: FeedArticle[];
  feed?: FeedGroupItem & {
    url: string;
  };
  headless?: boolean;
  hideForm?: boolean;
  hideToDos?: boolean;
  hideClassificationBadge?: boolean;
  hideNonShareActions?: boolean;
  hideNavigation?: boolean;
  loadingNavigation?: boolean;
  loadingSimilarArticles?: boolean;
  disableNextArticle?: boolean;
  disablePreviousArticle?: boolean;
  isLoading?: boolean;
  shareModalArticle: FeedArticle | null;
  updateArticle?: (
    params: Partial<UpdateArticleParams>
  ) => Promise<AxiosResponse<UpdateArticleResponse>>;
  onPreviousArticle?: () => void;
  onNextArticle?: () => void;
  setShareModalArticle: (article: FeedArticle | null) => void;
  hideSimilarArticles?: boolean;
}

const ArticleDetails = ({
  hideToDos,
  hideClassificationBadge,
  updateArticle,
  headless,
  isLoading,
  similarArticles,
  hideSimilarArticles,
  shareModalArticle,
  setShareModalArticle,
  ...context
}: IArticleDetailsProps) => {
  const { currentCompany } = useCompany();
  const [showToDosModal, setShowToDosModal] = useState(false);
  const { setToggleMenuBar, toggleMenuBar } = useHorizonScanningMenuBar();
  const { setActiveTab } = useHorizonScanningViewMode();

  const { article, feed, hideForm } = context;

  const onMarkAsRead = () => {
    if (!article.is_active) return;
    updateArticle?.({
      is_read: !article.is_read,
    });
  };

  const onArchive = () => {
    if (!article.is_active) return;
    updateArticle?.({
      is_archived: !article.is_archived,
    });
  };

  const onBookmark = () => {
    if (!article.is_active) return;

    updateArticle?.({
      is_saved: !article.is_saved,
    });
  };

  const breadCrumb = [
    {
      id: null,
      name: "Horizon Scanning",
      link: `/workspace/${currentCompany?.id}/horizon-scanning/feeds`,
    },
    {
      id: feed?.id ?? null,
      name: feed?.name ?? "",
      link: `/workspace/${currentCompany?.id}/horizon-scanning/feeds/${feed?.id}`,
    },
    {
      id: Number(article.object_id),
      name: truncate(article.title, { length: 67 }),
      link: `/workspace/${currentCompany?.id}/horizon-scanning/feeds/${feed?.id}/article/${article.object_id}`,
    },
  ];

  return (
    <div
      className={`relative flex flex-col flex-1 w-full bg-white dark:bg-mirage rounded-lg ${
        !headless ? "border border-gray-200 dark:border-jet" : ""
      }`}
    >
      {!headless ? (
        <Header
          moduleName="horizon-scanning"
          breadCrumb={breadCrumb}
          displaySidebarBtn={!toggleMenuBar}
          onSidebarBtnClick={() => {
            setActiveTab("comments");
            setToggleMenuBar(true);
          }}
        />
      ) : null}
      <ArticleDetailsContext.Provider
        value={{
          ...context,
          similarArticles,
          showToDosModal,
          setShowToDosModal,
          hideToDos,
          hideClassificationBadge,
          headless,
          onMarkAsRead,
          onArchive,
          onBookmark,
          updateArticle,
          openShareModal: setShareModalArticle,
        }}
      >
        <div className={cn(style.article_details_grid, headless ? "!pt-0" : "")}>
          {!headless ? <ActionBar /> : null}
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <ArticleHeader />

              <div className="grid gap-2">
                {!hideForm ? <ArticleFormMetadata /> : null}
                <ArticleDetailsMetadata />
              </div>

              <div className="h-px bg-antiflashwhite dark:bg-jet" />

              <ArticleSummary />

              <div className="h-px bg-antiflashwhite dark:bg-jet" />

              <ArticleClassifications />

              {!hideSimilarArticles && <ArticleSimilarArticles />}
            </>
          )}
        </div>

        {!hideToDos && showToDosModal ? <ArticleToDosModal /> : null}
        <ShareArticleModal
          article={shareModalArticle}
          open={!!shareModalArticle}
          onClose={() => setShareModalArticle(null)}
        />
      </ArticleDetailsContext.Provider>
    </div>
  );
};

export const useArticleDetails = () => useContext(ArticleDetailsContext);

export default ArticleDetails;
