import { memo, SetStateAction, useRef, useState } from "react";

import { useMediaQuery, useWindowSize } from "usehooks-ts";
import clsx from "clsx";
import { Modal, Tabs, Tooltip } from "flowbite-react";

import { getElementOffSetTop } from "shared/helpers/util";
import { useTeam } from "shared/store/settings";
import { MOBILE_DEVICE } from "shared/helpers/constant";
import useScreen from "shared/hooks/useScreen";

import { RelatedObjectType } from "modules/tasks/overview/types";
import styles from "assets/css/metadata.module.css";

import Comments from "./comments/Comments";
import ActivityLog from "./activity-log/ActivityLog";
import TeamActions from "./team-actions/TeamActions";
import ScheduledTasks from "./scheduled-tasks";
import Permissions from "./permissions";
import { MetaTable } from "./types";
import useGetEntityId from "./hooks/useGetEntityId";
import Icon from "../icon/Icon";
import commonTheme from "shared/theme/theme";

export type MetaDataRelatedObjectTypeProps = {
  type: MetaTable;
  relatedObject?: RelatedObjectType | null;
};

export type MetaDataDisplayProps = {
  displayComments?: boolean;
  displayActivityLog?: boolean;
  displayTeamActions?: boolean;
  displayScheduledTasks?: boolean;
  displayPermissions?: boolean;
};

type MetaDataModalProp = {
  isOpen: boolean;
  setIsOpen: (isOpen: SetStateAction<boolean>) => void;
} & MetaDataRelatedObjectTypeProps &
  MetaDataDisplayProps;

const MetaData = ({
  type,
  relatedObject,
  displayComments = true,
  displayActivityLog = true,
  displayTeamActions = true,
  displayScheduledTasks = true,
  displayPermissions = false,
}: MetaDataRelatedObjectTypeProps & MetaDataDisplayProps) => {
  const { height } = useWindowSize();
  const [activeTab, setActiveTab] = useState<number>(0);

  const metaDataRef = useRef<HTMLDivElement | null>(null);

  const metaDataHeight = height - getElementOffSetTop(metaDataRef.current);

  const currentTeam = useTeam((state) => state.currentTeam);

  const id = useGetEntityId();

  const deviceType = useScreen();

  const tabContentHeight = deviceType === MOBILE_DEVICE ? height - 135 : metaDataHeight;

  return (
    <div
      ref={metaDataRef}
      className="right-tabs md:min-w-[450px] maxMd:min-w[320px] overflow-hidden"
      style={{ height: deviceType === MOBILE_DEVICE ? "100%" : metaDataHeight }}
    >
      <Tabs.Group
        theme={commonTheme}
        className={clsx(styles.metadata_mobile, "md:!flex-row gap-0")}
        aria-label="Default tabs"
        onActiveTabChange={(tab) => setActiveTab(tab)}
      >
        {displayComments ? (
          <Tabs.Item
            active={activeTab === 0}
            title={
              <Tooltip className="tooltip" content="Comments" arrow={false} placement="left">
                <Icon type="chat" fill={false} size="icon-md" />
              </Tooltip>
            }
          >
            <div className={clsx("maxMd:overflow-y-auto")} style={{ height: tabContentHeight }}>
              <Comments
                type={type}
                id={id}
                teamId={currentTeam?.id}
                companyId={currentTeam?.company}
                isAllTeam={currentTeam?.is_all_team || false}
              />
            </div>
          </Tabs.Item>
        ) : (
          ""
        )}
        {displayActivityLog ? (
          <Tabs.Item
            active={activeTab === 1}
            title={
              <Tooltip className="tooltip" content="Activity Log" arrow={false} placement="left">
                <Icon type="archive-box-fill" fill={false} size="icon-md" />
              </Tooltip>
            }
          >
            <div className={clsx("maxMd:overflow-y-auto")} style={{ height: tabContentHeight }}>
              <ActivityLog
                id={id}
                type={type}
                teamId={currentTeam?.id}
                companyId={currentTeam?.company}
                isAllTeam={currentTeam?.is_all_team || false}
              />
            </div>
          </Tabs.Item>
        ) : (
          ""
        )}
        {displayTeamActions ? (
          <Tabs.Item
            active={activeTab === 2}
            title={
              <Tooltip
                className="tooltip"
                content="Object Properties"
                arrow={false}
                placement="left"
              >
                <Icon type="tag" fill={false} size="icon-md" />
              </Tooltip>
            }
          >
            <div style={{ height: tabContentHeight }} className="overflow-y-auto maxMd:mt-0">
              <TeamActions
                type={type}
                id={id}
                teamId={currentTeam?.id}
                companyId={currentTeam?.company}
                isAllTeam={currentTeam?.is_all_team || false}
              />
            </div>
          </Tabs.Item>
        ) : (
          ""
        )}
        {displayScheduledTasks && relatedObject ? (
          <Tabs.Item
            active={activeTab === 3}
            title={
              <Tooltip className="tooltip" content="Scheduled Tasks" arrow={false} placement="left">
                <Icon type="pencil-alt" fill={false} size="icon-md" />
              </Tooltip>
            }
          >
            <div style={{ height: tabContentHeight }} className="overflow-y-auto maxMd:mt-0">
              <ScheduledTasks type={type} relatedObject={relatedObject} />
            </div>
          </Tabs.Item>
        ) : (
          ""
        )}
        {displayPermissions && relatedObject ? (
          <Tabs.Item
            active={activeTab === 4}
            title={
              <Tooltip className="tooltip" content="Permissions" arrow={false} placement="left">
                <Icon type="key-2" fill={false} size="icon-md" />
              </Tooltip>
            }
          >
            <Permissions type={type} relatedObject={relatedObject} />
          </Tabs.Item>
        ) : (
          ""
        )}
      </Tabs.Group>
    </div>
  );
};

const MetaDataWrapper = ({
  isOpen,
  setIsOpen,
  type,
  relatedObject,
  displayComments = true,
  displayActivityLog = true,
  displayTeamActions = true,
  displayScheduledTasks = true,
  displayPermissions = false,
}: MetaDataModalProp) => {
  const matches = useMediaQuery("(max-width: 767px)");

  const metadata = (
    <MetaData
      type={type}
      relatedObject={relatedObject}
      displayComments={displayComments}
      displayActivityLog={displayActivityLog}
      displayTeamActions={displayTeamActions}
      displayScheduledTasks={displayScheduledTasks}
      displayPermissions={displayPermissions}
    />
  );

  return (
    <div className={clsx({ hidden: !isOpen }, "bg-white dark:bg-darkjunglegreenss")}>
      {matches ? (
        <Modal
          size="lg"
          show={isOpen}
          onClose={() => setIsOpen(false)}
          dismissible={true}
          className={styles.modal}
        >
          <Modal.Header className="!p-3 maxMd:dark:!border-thunders" />
          <Modal.Body className="!p-0 maxMd:overflow-hidden">{metadata}</Modal.Body>
        </Modal>
      ) : (
        metadata
      )}
    </div>
  );
};

export default memo(MetaDataWrapper);
