import { SetStateAction, useRef, useState } from "react";

import clsx from "clsx";
import { AxiosResponse } from "axios";
import { Label, Modal, TextInput, Tooltip } from "flowbite-react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import posthog from "posthog-js";

import Icon from "shared/components/icon/Icon";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";

const ArticleShare = ({
  response,
  isShareModalOpen,
  setIsShareModalOpen,
}: {
  response: AxiosResponse | undefined;
  isShareModalOpen: boolean;
  setIsShareModalOpen: (isShareModalOpen: SetStateAction<boolean>) => void;
}) => {
  const articleURL = `${window.location.origin}/articles/overview/${response?.data.id}/public`;

  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const { documentBodyRef } = useDocumentBodyRef();

  const [isURLCopied, setIsURLCopied] = useState<boolean>(false);

  const copyArticleLink = () => {
    navigator.clipboard.writeText(articleURL);
    setIsURLCopied(true);
    setTimeout(() => {
      setIsURLCopied(false);
    }, 2000);
  };

  const getSliceString = (title: string, limit: number) => {
    if (title?.length > limit) {
      return `${title.slice(0, limit)}...`;
    }
    return title;
  };

  const handleShareClick = () => {
    posthog.capture("articleShared");
  };

  return (
    <div>
      <Modal
        dismissible={true}
        show={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        root={documentBodyRef}
        className="modal_mobileview_center"
      >
        <Modal.Header className="dark:!border-thunders">
          <div className="font-inter-semibold text-mirage dark:text-white text-lg">Share post</div>
        </Modal.Header>
        <Modal.Body className="overflow-x-hidden">
          <div className="flex maxMd:flex-wrap items-center mt-1.5 md:h-[9.375rem]">
            {response?.data.image_url ? (
              <img
                src={response?.data.image_url}
                alt="article-image"
                className="w-[13.75rem] maxMd:w-full h-full object-cover"
              />
            ) : null}
            <div
              className={clsx(
                { "rounded-lg": !response?.data.image_url },
                "md:h-full md:border border-lightsilver dark:border-thunders rounded-tr-lg rounded-br-lg flex items-center"
              )}
              data-testid="article-share-title"
            >
              <h3 className="md:mx-6 maxMd:mt-3 text-base md:text-xl font-inter-semibold dark:text-white">
                {getSliceString(response?.data?.title, 100)}
              </h3>
            </div>
          </div>
          <div className="mt-4 relative">
            <Label
              value="Copy Link"
              className="font-inter-medium mb-2 block !text-mirage dark:!text-white"
            />
            <div data-testid="article-share-link">
              <TextInput value={getSliceString(articleURL, 78)} readOnly className="copy_link" />
            </div>
            <div className="absolute right-3 top-10" ref={tooltipRef}>
              <Tooltip
                content={isURLCopied ? "Copied" : "Copy to clipboard"}
                animation="duration-500"
                className={clsx("custom_tooltip", { "w-[142px]": !isURLCopied })}
              >
                <button onClick={copyArticleLink}>
                  <Icon type="copy" fill={false} size="icon-xs" />
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="mt-4 md:mb-[2.375rem]">
            <Label
              value="Share Via"
              className="font-inter-medium mb-4 block !text-mirage dark:!text-white"
            />
            <FacebookShareButton
              url={articleURL}
              className="mr-4"
              onClick={() => handleShareClick()}
            >
              <Icon type="facebook" className="w-5 h-5 stroke-none" />
            </FacebookShareButton>
            <LinkedinShareButton
              url={articleURL}
              className="mr-4"
              onClick={() => handleShareClick()}
            >
              <Icon type="linkedin" className="w-5 h-5 stroke-none" />
            </LinkedinShareButton>
            <TwitterShareButton
              url={articleURL}
              className="mr-4"
              onClick={() => handleShareClick()}
            >
              <Icon type="x" className="w-5 h-5 stroke-none [&>path]:dark:fill-white" />
            </TwitterShareButton>
            <EmailShareButton url={articleURL} onClick={() => handleShareClick()}>
              <EnvelopeIcon className="w-5 h-5 dark:fill-white" />
            </EmailShareButton>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ArticleShare;
