import { Link } from "react-router-dom";
import truncate from "lodash/truncate";

import Icon from "shared/components/icon/Icon";

import { useArticleDetails } from "../../ArticleDetails";
import ArticleMetadata from "../article-metadata";
import { formatDate } from "shared/componentsV2/utils";

const ArticleDetailsMetadata = () => {
  const { article } = useArticleDetails();

  const detailsMetadata = [
    {
      label: "Article ID",
      meta: <span className="dark:text-gray-500">{article.object_id}</span>,
    },
    ...(article.publication_date !== null
      ? [
          {
            label: "Published at",
            meta: (
              <span className="dark:text-gray-500">
                {formatDate(Number(article.publication_date) * 1000)}
              </span>
            ), // date is returned in seconds
          },
        ]
      : []),
    {
      label: "Source",
      meta: (
        <Link
          to={`https://${article.publisher?.name}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-1 dark:text-gray-500"
        >
          {truncate(article.publisher?.name, { length: 61 })}
          <Icon type="external-link" fill size="icon-xs" badgeColor="#1C64F2" />
        </Link>
      ),
    },
    {
      label: "Link to original article",
      meta: (
        <Link
          to={article.link}
          target="_blank"
          className="flex items-center gap-1 dark:text-gray-500"
        >
          {truncate(article.title, { length: 61 })}
          <Icon type="external-link" fill size="icon-xs" badgeColor="#1C64F2" />
        </Link>
      ),
    },
  ];

  return <ArticleMetadata collapsible metadatas={detailsMetadata} sectionTitle="Details" />;
};

export default ArticleDetailsMetadata;
