import { useDarkMode } from "usehooks-ts";

import Badge from "shared/componentsV2/form-controls/badge";
import Icon from "shared/components/icon/Icon";

import { useArticleDetails } from "../../ArticleDetails";
import { useTodosFormState } from "../article-to-dos-modal/ArticleToDosModal";

const ArticleHeader = () => {
  const { article, setShowToDosModal, hideToDos, hideClassificationBadge, headless } =
    useArticleDetails();

  const [todos] = useTodosFormState();

  const totalTodosAmount = todos.filter(({ is_deleted }) => !is_deleted).length;
  const completedTodosAmount = todos.filter(
    ({ is_completed, is_deleted }) => is_completed && !is_deleted
  ).length;

  const { isDarkMode } = useDarkMode();

  return (
    <div className="grid gap-3">
      {article.title ? (
        <h1
          className={`font-semibold text-richblack dark:text-lighthouse text-xl ${
            headless ? "md:text-4xl" : "text-xl"
          }`}
        >
          {article.title}
        </h1>
      ) : null}
      {article.original_title ? (
        <div className="flex gap-1 text-xs">
          <span className="font-semibold dark:text-gray-400">Original title:</span>
          <span className="font-normal dark:text-gray-500">{article.original_title}</span>
        </div>
      ) : null}
      <div className="flex gap-2">
        {article.has_classification && !hideClassificationBadge ? (
          <Badge
            badgeColor={isDarkMode ? "#9CA3AF" : "#4B5563"}
            icon={<Icon type="squares-check" size="icon-xxs" />}
            badgeTitle="Classified"
          />
        ) : null}
        {!hideToDos ? (
          <Badge
            className="!border-brightgray dark:border-thunders cursor-pointer"
            icon={<Icon type="to-do" size="icon-xxs" />}
            badgeColor={isDarkMode ? "#9CA3AF" : "#4B5563"}
            badgeBgColor="transparent"
            badgeTitle={
              <div className="flex gap-1 cursor-pointer">
                To do
                <span className="text-newcar">{`${completedTodosAmount}/${totalTodosAmount}`}</span>
              </div>
            }
            badgeType="secondary"
            onClick={() => setShowToDosModal?.(true)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ArticleHeader;
