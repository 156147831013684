import { useEffect, useState } from "react";

import clsx from "clsx";

import Button from "shared/componentsV2/form-controls/button";
import Checkbox from "shared/componentsV2/form-controls/checkbox";
import Icon from "shared/components/icon/Icon";
import Modal from "shared/componentsV2/common-modal";

import { ToDo } from "modules/horizonScanning/types";
import { useArticleDetails } from "../../ArticleDetails";
import styles from "assets/css/articlesV2.module.css";
import TodoItem from "./components/to-do";

const createRandomId = () => Math.round(Math.random() * 100000000);

export const useTodosFormState = () => {
  const { article, feed } = useArticleDetails();

  const [todosFormState, setTodosFormState] = useState<ToDo[]>([]);

  // initialize todos form state
  const notInArticleTodosYet = ({ id }: ToDo) =>
    !article.todos?.find(({ parent_todo }) => parent_todo === id);

  useEffect(() => {
    setTodosFormState([
      ...(feed?.template_todos.filter(notInArticleTodosYet).map((todo) => ({
        ...todo,
        id: createRandomId(),
        parent_todo: todo.id ? Number(todo.id) : undefined,
      })) ?? []),
      ...(article.todos ?? []),
    ]);
  }, [article.todos, feed?.template_todos]);

  return [todosFormState, setTodosFormState] as const;
};

interface IArticleToDosModalProps {
  onClose?: () => void;
}

const ArticleToDosModal = ({ onClose }: IArticleToDosModalProps) => {
  const { showToDosModal, setShowToDosModal, updateArticle } = useArticleDetails();

  const [todosFormState, setTodosFormState] = useTodosFormState();
  const [newTodoText, setNewTodoText] = useState("");

  const onCreateNewTodo = () => {
    if (!newTodoText.trim()) {
      return;
    }

    setTodosFormState((prev) => [
      ...prev,
      {
        id: createRandomId(),
        description: newTodoText,
        is_completed: false,
        is_deleted: false,
        parent_todo: null,
      },
    ]);

    setNewTodoText("");
  };

  const completedTodosAmount = todosFormState.filter(
    ({ is_completed, is_deleted }) => is_completed && !is_deleted
  ).length;

  return (
    <Modal
      onClose={() => {
        setShowToDosModal?.(false);
        onClose?.();
      }}
      show={showToDosModal}
      size="4xl"
      dismissible={false}
    >
      <Modal.Header>
        <div className="flex items-center gap-2 text-sm font-medium text-richblack dark:text-gray-400">
          <Icon type="in-progress" />
          To-do
          {todosFormState.length ? (
            <span>
              {completedTodosAmount}/{todosFormState.filter(({ is_deleted }) => !is_deleted).length}
            </span>
          ) : null}
        </div>
      </Modal.Header>
      <Modal.Body className="!px-8 !py-6">
        <div className="grid gap-4">
          <span className="text-sm font-medium text-aurometalsaurus">
            The below tasks must be completed before the article status can be changed to complete.
          </span>
          <ul className="grid gap-2 list-none w-full text-sm font-medium">
            {todosFormState.map((todo) =>
              !todo.is_deleted ? (
                <TodoItem
                  key={todo.id}
                  todo={todo}
                  onChangeTodo={(newValues) => {
                    if (
                      todo.parent_todo &&
                      newValues.description &&
                      todo.description !== newValues.description
                    ) {
                      setTodosFormState((prev) =>
                        prev.flatMap((item) =>
                          item.id === todo.id
                            ? [
                                { ...item, is_deleted: true },
                                { ...item, ...newValues, parent_todo: null, id: createRandomId() },
                              ]
                            : item
                        )
                      );
                      return;
                    }

                    setTodosFormState((prev) =>
                      prev.map((item) => (item.id === todo.id ? { ...item, ...newValues } : item))
                    );
                  }}
                />
              ) : null
            )}
            <form
              onSubmit={(event) => {
                event.preventDefault();
                onCreateNewTodo();
              }}
            >
              <li
                className={clsx(
                  styles.article_to_do,
                  "grid items-center grid-cols-[auto_1fr_auto] gap-2 rounded-lg px-3 py-2 bg-ghostwhite dark:bg-balticsea"
                )}
              >
                <Checkbox
                  checked={false}
                  disabled
                  wrapperClassName="!p-0"
                  className="w-3.5 h-3.5"
                  onChange={() => undefined}
                />

                <input
                  onBlur={() => onCreateNewTodo()}
                  className="w-full bg-transparent text-sm font-medium grow !px-0 !py-1"
                  placeholder="Add item..."
                  value={newTodoText}
                  onChange={(event) => setNewTodoText(event.target.value)}
                />
              </li>
            </form>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex justify-end items-center !px-8 !py-3 border-0">
        <Button
          btnTitle="Discard"
          btnType="secondary"
          btnSize="sm"
          onClick={() => {
            setShowToDosModal?.(false);
          }}
        />
        <Button
          btnTitle="Save"
          btnSize="sm"
          onClick={() => {
            updateArticle?.({ todos: todosFormState });
            setShowToDosModal?.(false);
            onClose?.();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ArticleToDosModal;
