import { AxiosResponse } from "axios";
import { FeedArticle } from "modules/horizonScanning/types";
import api from "shared/api/v1";
import { MeiliResponse } from "shared/typesV2";

export default async function getPublicArticle(params: {
  articleId: number;
}): Promise<AxiosResponse<MeiliResponse<FeedArticle>>> {
  const qs = new URLSearchParams({
    company: "0",
    filters: JSON.stringify({
      key: "object_id",
      operator: "=",
      value: `${params.articleId}`,
    }),
  });

  return api.get<MeiliResponse<FeedArticle>>(
    `/horizon-scanning/public-listing-articles/search/?${qs.toString()}`
  );
}
