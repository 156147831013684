import {
  ArticleClassifications as EArticleClassifications,
  IClassificationData,
} from "modules/horizonScanning/types";
import ArticleSection from "../article-section";
import { useArticleDetails } from "../../ArticleDetails";

const ArticleClassifications = () => {
  const { article, hideClassificationBadge } = useArticleDetails();

  const addSeparator = (data: string[] | IClassificationData[] | null) => {
    return data?.map((d) => (typeof d === "object" && "name" in d ? d.name : d)).join(" | ");
  };

  const classifications = [
    {
      title: EArticleClassifications.relevantRegulations,
      classification: addSeparator(article.regulations),
    },
    {
      title: EArticleClassifications.mitigatingEfforts,
      classification: addSeparator(article.mitigating_efforts || []),
    },
    {
      title: EArticleClassifications.potentialImpact,
      classification: addSeparator(article.potential_impact || []),
    },
    {
      title: EArticleClassifications.timeline,
      classification: addSeparator(article.timeline || []),
    },
    {
      title: EArticleClassifications.institutionTypes,
      classification: addSeparator(article.institution_types),
    },
    {
      title: EArticleClassifications.regulatoryJurisdiction,
      classification: addSeparator(article.jurisdictions),
    },
  ];

  return (
    <ArticleSection title={hideClassificationBadge ? null : "Classifications"} icon="squares-check">
      <div className="grid gap-2">
        {classifications.map(({ classification, title }) =>
          classification ? (
            <div
              key={title}
              className="grid gap-2 border border-antiflashwhite rounded-lg p-4 bg-ghostwhite font-medium dark:border-jet dark:bg-darkjunglegreenss"
            >
              <span className="text-xs text-aurometalsaurus dark:text-gray-400">{title}</span>
              <span className="text-sm text-richblack dark:text-gray-500">{classification}</span>
            </div>
          ) : null
        )}
      </div>
    </ArticleSection>
  );
};

export default ArticleClassifications;
