import ArticleSection from "../article-section";
import { useArticleDetails } from "../../ArticleDetails";
import { FeedItem } from "modules/horizonScanning/feed-details/components/FeedItems";
import Button from "shared/componentsV2/form-controls/button";
import Icon from "shared/components/icon/Icon";
import { Link } from "react-router-dom";
import { useCompany } from "shared/context/CompanyProvider";
import { Spinner } from "flowbite-react";

const ArticleSimilarArticles = () => {
  const { currentCompany } = useCompany();
  const { article, feed, similarArticles, openShareModal, updateArticle, loadingSimilarArticles } =
    useArticleDetails();

  return (
    <ArticleSection title="Similar articles" icon="newspaper">
      {loadingSimilarArticles ? (
        <div className="justify-self-center">
          <Spinner size="xl" className="!fill-mirage" />
        </div>
      ) : (
        <>
          <div className="grid gap-2">
            {!similarArticles || similarArticles.length === 0 ? (
              <div className="justify-self-center">No similar articles available</div>
            ) : null}
            {similarArticles?.map((similarArticle) => (
              <FeedItem
                key={similarArticle.object_id}
                item={similarArticle}
                onAction={(article, action) => {
                  if (action === "share") {
                    openShareModal?.(article);
                    return;
                  }

                  updateArticle?.({
                    companyId: Number(article.company_id) || currentCompany.id,
                    feedId: Number(article.feed_id),
                    id: article.object_id,
                    ...(action === "archive" ? { is_archived: !article.is_archived } : {}),
                    ...(action === "read" ? { is_read: !article.is_read } : {}),
                    ...(action === "save" ? { is_saved: !article.is_saved } : {}),
                  });
                }}
              />
            ))}
          </div>
        </>
      )}
      {similarArticles && similarArticles.length > 0 && (
        <Link
          className="justify-self-center"
          to={`/workspace/${currentCompany.id}/horizon-scanning/feeds/${feed?.id}/article/${article.object_id}/similar`}
        >
          <Button
            btnType="secondary"
            btnSize="sm"
            btnTitle="See more similar articles"
            btnIconAlign="right"
            className="w-fit"
            icon={<Icon type="arrow-right-new" />}
          />
        </Link>
      )}
    </ArticleSection>
  );
};

export default ArticleSimilarArticles;
